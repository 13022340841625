<script setup lang="ts">
const items = ref([
    [
        {
            label: 'Riza Masykur',
            avatar: {
                src: 'https://github.com/benjamincanac.png',
            },
            type: 'label',
        },
    ],
])
const menus = ref([
    {
        label: 'Notifications',
    },
    {
        label: 'Events',
    },
])
</script>

<template>
    <UDropdownMenu
        :items="items"
        :ui="{
            content: 'w-60',
        }"
    >
        <UChip inset>
            <UButton
                class="cursor-pointer"
                icon="si:notifications-thick-line"
                color="neutral"
                variant="ghost"
            />
        </UChip>
        <template #item>
            <UTabs
                size="xs"
                color="neutral"
                variant="link"
                :items="menus"
                class="-mt-2 w-full"
            >
                <template #content="{ item }">
                    <div class="font-normal">
                        This is the {{ item.label }} tab.
                    </div>
                </template>
            </UTabs>
        </template>
    </UDropdownMenu>
</template>
